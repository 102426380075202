import React from "react"
import { Image } from "semantic-ui-react"
import Layout from "../../components/layout"
import { todolist } from "../../data/student's-corner"
import "../../styles/to-do-list.css"
import SEO from "../../components/seo"
function ToDoList(props) {
  return (
    <div>
      <Layout>
        <SEO
          title="To-do List"
          description="visit old KB at night - There are many urban legends surrounding this eerie building near Govind bhawan. Over the years the curious inhabitants of R-land have cooked up quite a few stories to explain how the building got to its current state. Although there’s no evidence to support these outrageous claims, if haunted places and urban legends interest you, you should definitely pay old KB a visit at night and maybe decipher its mysteries yourself"
        />
        <h2 className="dark-color-head">To-do List</h2>
        <p className="list-head">
          A list of must haves and things required to help you have a wonderful
          stay at IIT Roorkee!
        </p>
        <div>
          {todolist.map(todo => (
            <div className="todo">
              <div className="icon-head">
                <Image
                  src={require("../../images/icons/flag.png")}
                  className="flag-icon"
                />
                <p className="normal-para">
                  {" "}
                  <strong>{todo.title}</strong> {todo.content}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div
          className="normal-para para-container"
          dangerouslySetInnerHTML={{
            __html: props.content,
          }}
        />
      </Layout>
    </div>
  )
}

export default ToDoList
